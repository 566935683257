/* swiper public */
.swiper-container {
  @include mobile {
    padding-bottom: 35px; }
  @include tablet {
    padding-bottom: 45px; }
  @include desktop {
    padding: 10px; }
  &.swiper-container .swiper-pagination {
    @include tablet {
      display: block;
      bottom: 4px; }
    @include desktop {
      display: none; } }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    @include desktop {
      @include box-shadow($box-shadow: 0px 0px 10px 0px rgba(0%,0%,0%,0.0));
      @include hoverEffect($transition: box-shadow 0.5s ease-in-out);
      &:hover {
        @include box-shadow($box-shadow: 0px 0px 10px 0px rgba(0%,0%,0%,0.3)); } }
    a {
      display: block;
      overflow: hidden;
      .itemImg {
        position: relative;
        max-width: 100%;
        margin: auto;
        @include desktop {
          overflow: hidden; }
        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          min-height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center center; } }
      .theInfo {
        overflow: hidden; } } }

  .swiper-button-next, .swiper-button-prev {
    width: 18px;
    height: 32px;
    @include belowTablet {
      display: none; }
    @include desktop {
      display: block; } }
  .swiper-pagination-bullet {
    width: 15px;
    height: 15px; }
  .swiper-pagination-bullet-active {
    width: 40px;
    height: 15px;
    border-radius: 20px;
    background: #00a0e9; } }
.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 4px; }
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url(../../images/icon/arrow-next.svg);
  background-size: cover; }
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url(../../images/icon/arrow-back.svg);
  background-size: cover; }
.forArrow {
  position: relative;
  overflow: visible;
  .swiper-button-prev, .swiper-button-next {
    margin-top: 5px;
    position: absolute;
    top: 50%; }
  .swiper-button-prev {
    left: -38px; }
  .swiper-button-next {
    right: -38px; } }

.swiper-button-next, .swiper-button-prev {
  width: 18px;
  height: 32px; }
