// *************************************
//
//   Qsquare Specified color - UI Color Palette
//
// *************************************

$main-Blue-1: #53c6f1;
$main-Blue-2: #00aeef;
$main-Blue-3: #1e87f0;
$main-Blue-4: #408ab4;
$main-gray-line: #dddddd;
$main-gray-1: #bbbbbb;
$main-gray-2: #adadb0;
$main-gray-3: #cccccc;
$main-gray-4: #999999;
$main-gray-5: #7c7d7f;
$main-gray-6: #666666;
$main-gray-7: #333333;
$main-gray-footerBg: #6d6e71;

//-$primary
//-$secondary
// $accent
//-$success
//-$warning
//-$danger
//-$info




// *************************************
//
//   UI Color Palette (colors from Google Material Design)
//   -> The color palette starts with primary colors and fills in the spectrum
//   -> to create a complete and usable palette for Android, Web, and iOS.
//   -> The primary colors are the 500 range.
//   -> http://www.google.com/design/spec/style/color.html#color-ui-color-palette
//
// *************************************


//  Red
// -------------------------------------

$red-50: #fde0dc;
$red-100: #f9bdbb;
$red-200: #f69988;
$red-300: #f36c60;
$red-400: #e84e40;
$red-500: #e51c23;
$red-600: #dd191d;
$red-700: #d01716;
$red-800: #c41411;
$red-900: #b0120a;
$red-a100: #ff7997;
$red-a200: #ff5177;
$red-a400: #ff2d6f;
$red-a700: #e00032;


//  Pink
// -------------------------------------

$pink-50: #fce4ec;
$pink-100: #f8bbd0;
$pink-200: #f48fb1;
$pink-300: #f06292;
$pink-400: #ec407a;
$pink-500: #e91e63;
$pink-600: #d81b60;
$pink-700: #c2185b;
$pink-800: #ad1457;
$pink-900: #880e4f;
$pink-a100: #ff80ab;
$pink-a200: #ff4081;
$pink-a400: #f50057;
$pink-a700: #c51162;


//  Purple
// -------------------------------------

$purple-50: #f3e5f5;
$purple-100: #e1bee7;
$purple-200: #ce93d8;
$purple-300: #ba68c8;
$purple-400: #ab47bc;
$purple-500: #9c27b0;
$purple-600: #8e24aa;
$purple-700: #7b1fa2;
$purple-800: #6a1b9a;
$purple-900: #4a148c;
$purple-a100: #ea80fc;
$purple-a200: #e040fb;
$purple-a400: #d500f9;
$purple-a700: #aa00ff;


//  Deep purple
// -------------------------------------

$deepPurple-50: #ede7f6;
$deepPurple-100: #d1c4e9;
$deepPurple-200: #b39ddb;
$deepPurple-300: #9575cd;
$deepPurple-400: #7e57c2;
$deepPurple-500: #673ab7;
$deepPurple-600: #5e35b1;
$deepPurple-700: #512da8;
$deepPurple-800: #4527a0;
$deepPurple-900: #311b92;
$deepPurple-a100: #b388ff;
$deepPurple-a200: #7c4dff;
$deepPurple-a400: #651fff;
$deepPurple-a700: #6200ea;


//  Indigo
// -------------------------------------

$indigo-50: #e8eaf6;
$indigo-100: #c5cae9;
$indigo-200: #9fa8da;
$indigo-300: #7986cb;
$indigo-400: #5c6bc0;
$indigo-500: #3f51b5;
$indigo-600: #3949ab;
$indigo-700: #303f9f;
$indigo-800: #283593;
$indigo-900: #1a237e;
$indigo-a100: #8c9eff;
$indigo-a200: #536dfe;
$indigo-a400: #3d5afe;
$indigo-a700: #304ffe;


//  Blue
// -------------------------------------

$blue-50: #e7e9fd;
$blue-100: #d0d9ff;
$blue-200: #afbfff;
$blue-300: #91a7ff;
$blue-400: #738ffe;
$blue-500: #5677fc;
$blue-600: #4e6cef;
$blue-700: #455ede;
$blue-800: #3b50ce;
$blue-900: #2a36b1;
$blue-a100: #a6baff;
$blue-a200: #6889ff;
$blue-a400: #4d73ff;
$blue-a700: #4d69ff;


//  Light blue
// -------------------------------------

$lightBlue-50: #e1f5fe;
$lightBlue-100: #b3e5fc;
$lightBlue-200: #81d4fa;
$lightBlue-300: #4fc3f7;
$lightBlue-400: #29b6f6;
$lightBlue-500: #03a9f4;
$lightBlue-600: #039be5;
$lightBlue-700: #0288d1;
$lightBlue-800: #0277bd;
$lightBlue-900: #01579b;
$lightBlue-a100: #80d8ff;
$lightBlue-a200: #40c4ff;
$lightBlue-a400: #00b0ff;
$lightBlue-a700: #0091ea;


//  Cyan
// -------------------------------------

$cyan-50: #e0f7fa;
$cyan-100: #b2ebf2;
$cyan-200: #80deea;
$cyan-300: #4dd0e1;
$cyan-400: #26c6da;
$cyan-500: #00bcd4;
$cyan-600: #00acc1;
$cyan-700: #0097a7;
$cyan-800: #00838f;
$cyan-900: #006064;
$cyan-a100: #84ffff;
$cyan-a200: #18ffff;
$cyan-a400: #00e5ff;
$cyan-a700: #00b8d4;


//  Teal
// -------------------------------------

$teal-50: #e0f2f1;
$teal-100: #b2dfdb;
$teal-200: #80cbc4;
$teal-300: #4db6ac;
$teal-400: #26a69a;
$teal-500: #009688;
$teal-600: #00897b;
$teal-700: #00796b;
$teal-800: #00695c;
$teal-900: #004d40;
$teal-a100: #a7ffeb;
$teal-a200: #64ffda;
$teal-a400: #1de9b6;
$teal-a700: #00bfa5;


//  Green
// -------------------------------------

$green-50: #d0f8ce;
$green-100: #a3e9a4;
$green-200: #72d572;
$green-300: #42bd41;
$green-400: #2baf2b;
$green-500: #259b24;
$green-600: #0a8f08;
$green-700: #0a7e07;
$green-800: #056f00;
$green-900: #0d5302;
$green-a100: #a2f78d;
$green-a200: #5af158;
$green-a400: #14e715;
$green-a700: #12c700;


//  Light green
// -------------------------------------

$lightGreen-50: #f1f8e9;
$lightGreen-100: #dcedc8;
$lightGreen-200: #c5e1a5;
$lightGreen-300: #aed581;
$lightGreen-400: #9ccc65;
$lightGreen-500: #8bc34a;
$lightGreen-600: #7cb342;
$lightGreen-700: #689f38;
$lightGreen-800: #558b2f;
$lightGreen-900: #33691e;
$lightGreen-a100: #ccff90;
$lightGreen-a200: #b2ff59;
$lightGreen-a400: #76ff03;
$lightGreen-a700: #64dd17;


//  Lime
// -------------------------------------

$lime-50: #f9fbe7;
$lime-100: #f0f4c3;
$lime-200: #e6ee9c;
$lime-300: #dce775;
$lime-400: #d4e157;
$lime-500: #cddc39;
$lime-600: #c0ca33;
$lime-700: #afb42b;
$lime-800: #9e9d24;
$lime-900: #827717;
$lime-a100: #f4ff81;
$lime-a200: #eeff41;
$lime-a400: #c6ff00;
$lime-a700: #aeea00;


//  Yellow
// -------------------------------------

$yellow-50: #fffde7;
$yellow-100: #fff9c4;
$yellow-200: #fff59d;
$yellow-300: #fff176;
$yellow-400: #ffee58;
$yellow-500: #ffeb3b;
$yellow-600: #fdd835;
$yellow-700: #fbc02d;
$yellow-800: #f9a825;
$yellow-900: #f57f17;
$yellow-a100: #ffff8d;
$yellow-a200: #ffff00;
$yellow-a400: #ffea00;
$yellow-a700: #ffd600;


//  Amber
// -------------------------------------

$amber-50: #fff8e1;
$amber-100: #ffecb3;
$amber-200: #ffe082;
$amber-300: #ffd54f;
$amber-400: #ffca28;
$amber-500: #ffc107;
$amber-600: #ffb300;
$amber-700: #ffa000;
$amber-800: #ff8f00;
$amber-900: #ff6f00;
$amber-a100: #ffe57f;
$amber-a200: #ffd740;
$amber-a400: #ffc400;
$amber-a700: #ffab00;


//  Orange
// -------------------------------------

$orange-50: #fff3e0;
$orange-100: #ffe0b2;
$orange-200: #ffcc80;
$orange-300: #ffb74d;
$orange-400: #ffa726;
$orange-500: #ff9800;
$orange-600: #fb8c00;
$orange-700: #f57c00;
$orange-800: #ef6c00;
$orange-900: #e65100;
$orange-a100: #ffd180;
$orange-a200: #ffab40;
$orange-a400: #ff9100;
$orange-a700: #ff6d00;


//  Deep orange
// -------------------------------------

$deepOrange-50: #fbe9e7;
$deepOrange-100: #ffccbc;
$deepOrange-200: #ffab91;
$deepOrange-300: #ff8a65;
$deepOrange-400: #ff7043;
$deepOrange-500: #ff5722;
$deepOrange-600: #f4511e;
$deepOrange-700: #e64a19;
$deepOrange-800: #d84315;
$deepOrange-900: #bf360c;
$deepOrange-a100: #ff9e80;
$deepOrange-a200: #ff6e40;
$deepOrange-a400: #ff3d00;
$deepOrange-a700: #dd2c00;


//  Brown
// -------------------------------------

$brown-50: #efebe9;
$brown-100: #d7ccc8;
$brown-200: #bcaaa4;
$brown-300: #a1887f;
$brown-400: #8d6e63;
$brown-500: #795548;
$brown-600: #6d4c41;
$brown-700: #5d4037;
$brown-800: #4e342e;
$brown-900: #3e2723;


//  Gray
// -------------------------------------

$gray-50: #fafafa;
$gray-100: #f5f5f5;
$gray-200: #eeeeee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;
$white: #ffffff;
$black: #000000;


//  Blue gray
// -------------------------------------

$blueGray-50: #eceff1;
$blueGray-100: #cfd8dc;
$blueGray-200: #b0bec5;
$blueGray-300: #90a4ae;
$blueGray-400: #78909c;
$blueGray-500: #607d8b;
$blueGray-600: #546e7a;
$blueGray-700: #455a64;
$blueGray-800: #37474f;
$blueGray-900: #263238;
