/*fonts*/
/* swiper public */
@media (max-width: 767px) {
  .swiper-container {
    padding-bottom: 35px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .swiper-container {
    padding-bottom: 45px;
  }
}

@media (min-width: 1025px) {
  .swiper-container {
    padding: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .swiper-container.swiper-container .swiper-pagination {
    display: block;
    bottom: 4px;
  }
}

@media (min-width: 1025px) {
  .swiper-container.swiper-container .swiper-pagination {
    display: none;
  }
}

.swiper-container .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
}

@media (min-width: 1025px) {
  .swiper-container .swiper-slide {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
    /* box-shadow: x y blur spread color inset */
    transition: box-shadow 0.5s ease-in-out;
  }
  .swiper-container .swiper-slide:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    /* box-shadow: x y blur spread color inset */
  }
}

.swiper-container .swiper-slide a {
  display: block;
  overflow: hidden;
}

.swiper-container .swiper-slide a .itemImg {
  position: relative;
  max-width: 100%;
  margin: auto;
}

@media (min-width: 1025px) {
  .swiper-container .swiper-slide a .itemImg {
    overflow: hidden;
  }
}

.swiper-container .swiper-slide a .itemImg img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}

.swiper-container .swiper-slide a .theInfo {
  overflow: hidden;
}

.swiper-container .swiper-button-next, .swiper-container .swiper-button-prev {
  width: 18px;
  height: 32px;
}

@media (max-width: 1024px) {
  .swiper-container .swiper-button-next, .swiper-container .swiper-button-prev {
    display: none;
  }
}

@media (min-width: 1025px) {
  .swiper-container .swiper-button-next, .swiper-container .swiper-button-prev {
    display: block;
  }
}

.swiper-container .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
}

.swiper-container .swiper-pagination-bullet-active {
  width: 40px;
  height: 15px;
  border-radius: 20px;
  background: #00a0e9;
}

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 4px;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url(../../images/icon/arrow-next.svg);
  background-size: cover;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url(../../images/icon/arrow-back.svg);
  background-size: cover;
}

.forArrow {
  position: relative;
  overflow: visible;
}

.forArrow .swiper-button-prev, .forArrow .swiper-button-next {
  margin-top: 5px;
  position: absolute;
  top: 50%;
}

.forArrow .swiper-button-prev {
  left: -38px;
}

.forArrow .swiper-button-next {
  right: -38px;
}

.swiper-button-next, .swiper-button-prev {
  width: 18px;
  height: 32px;
}

/* kv -------------------------------- */
@media (max-width: 767px) {
  .kv {
    height: auto;
    padding-bottom: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .kv {
    height: 475px;
    padding-bottom: 0px;
  }
}

@media (min-width: 1025px) {
  .kv {
    height: 475px;
    padding: 0px;
  }
}

@media (max-width: 767px) {
  .kv .swiper-slide {
    height: auto;
  }
  .kv .swiper-slide a {
    display: flex;
  }
}

@media (max-width: 767px) {
  .kv .noInfo {
    height: 250px;
  }
  .kv .noInfo a {
    display: block;
  }
}

.kv .swiper-button-next, .kv .swiper-button-prev {
  width: 27px;
  height: 44px;
}

@media (max-width: 1024px) {
  .kv .swiper-button-next, .kv .swiper-button-prev {
    display: none;
  }
}

@media (min-width: 1025px) {
  .kv .swiper-button-next, .kv .swiper-button-prev {
    display: block;
  }
}

.kv .swiper-pagination {
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .kv .swiper-pagination {
    top: 220px;
    bottom: unset;
  }
}

@media (max-width: 1024px) {
  .kv .swiper-pagination {
    display: block;
    margin-bottom: 5px;
  }
}

@media (min-width: 1025px) {
  .kv .swiper-pagination {
    display: block;
  }
}

.kv .swiper-pagination-bullet {
  background: #e5e5e5;
  opacity: 1;
}

.kv .swiper-pagination-bullet-active {
  opacity: 1;
  background: #00a0e9;
}

.kv .swiper-button-next, .kv .swiper-container-rtl .swiper-button-prev {
  background-image: url(../../images/icon/arrow-next-w.svg);
}

.kv .swiper-button-prev, .kv .swiper-container-rtl .swiper-button-next {
  background-image: url(../../images/icon/arrow-back-w.svg);
}

/* kvWrap */
.kvWrap {
  width: 100%;
  overflow: hidden;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .kvWrap {
    height: 475px;
  }
}

@media (min-width: 1025px) {
  .kvWrap {
    height: 475px;
  }
}

.kvWrap a {
  overflow: hidden;
}

@media (max-width: 767px) {
  .kvWrap a {
    display: flex;
    flex-flow: row wrap;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .kvWrap a {
    display: block;
  }
}

@media (min-width: 1025px) {
  .kvWrap a {
    display: block;
  }
}

@media (max-width: 767px) {
  .kvWrap a .itemImg {
    width: 100%;
    overflow: hidden;
    height: 250px;
    order: 1;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .kvWrap a .itemImg {
    position: relative;
    max-width: 100%;
    margin: auto;
    height: 475px;
  }
}

@media (min-width: 1025px) {
  .kvWrap a .itemImg {
    position: relative;
    max-width: 100%;
    margin: auto;
    height: 475px;
    overflow: hidden;
  }
}

@media (min-width: 768px) {
  .kvWrap a .itemImg img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    font-family: 'object-fit: cover; object-position: center center;';
  }
}

@media (max-width: 767px) {
  .kvWrap a .itemImg img.pic-mobile {
    display: block;
  }
}

@media (min-width: 768px) {
  .kvWrap a .itemImg img.pic-mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .kvWrap a .itemImg img.pic-desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .kvWrap a .itemImg img.pic-desktop {
    display: block;
  }
}

@media (max-width: 767px) {
  .kvWrap a .main {
    order: 2;
    padding: 0px;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .kvWrap a .main {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    height: 425px;
    bottom: 25px;
  }
}

@media (min-width: 1025px) {
  .kvWrap a .main {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    height: 475px;
    bottom: 0px;
  }
}

.kvWrap a .main .theInfo {
  display: block;
  background: linear-gradient(to bottom, rgba(25, 92, 196, 0.9) 0, rgba(13, 121, 212, 0.9) 50%, rgba(1, 159, 232, 0.5) 100%);
  background: -moz-linear-gradient(to bottom, rgba(25, 92, 196, 0.9) 0, rgba(13, 121, 212, 0.9) 50%, rgba(1, 159, 232, 0.5) 100%);
  background: -webkit-linear-gradient(to bottom, rgba(25, 92, 196, 0.9) 0, rgba(13, 121, 212, 0.9) 50%, rgba(1, 159, 232, 0.5) 100%);
  background: -o-linear-gradient(to bottom, rgba(25, 92, 196, 0.9) 0, rgba(13, 121, 212, 0.9) 50%, rgba(1, 159, 232, 0.5) 100%);
}

@media (max-width: 767px) {
  .kvWrap a .main .theInfo {
    height: auto;
    width: calc( 100% - 30px);
    padding: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .kvWrap a .main .theInfo {
    position: absolute;
    bottom: 20px;
    width: calc( 100% - 86px);
    padding: 25px;
    opacity: 0;
    transform: translate3d(0, 30px, 0);
    transition: .8s ease .6s;
  }
}

@media (min-width: 1025px) {
  .kvWrap a .main .theInfo {
    position: absolute;
    bottom: 20px;
    width: 620px;
    padding: 18px;
    left: 10px;
    opacity: 0;
    transform: translate3d(0, 30px, 0);
    transition: .8s ease .6s;
  }
}

.kvWrap a .main .theInfo .the-title {
  overflow: hidden;
  opacity: 0;
  transform: translate3d(0, 30px, 0);
  transition: .8s ease .6s;
}

@media (max-width: 1024px) {
  .kvWrap a .main .theInfo .the-title {
    color: #ffffff;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    max-height: 160px;
  }
}

@media (min-width: 1025px) {
  .kvWrap a .main .theInfo .the-title {
    color: #ffffff;
    font-weight: normal;
    font-size: 26px;
    line-height: 34px;
    text-align: left;
    max-height: 68px;
  }
}

.kvWrap a .main .theInfo .the-text {
  color: #ffffff;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  overflow: hidden;
  margin-top: 5px;
  opacity: 0;
  transform: translate3d(0, 30px, 0);
  transition: .8s ease .6s;
}

@media (max-width: 767px) {
  .kvWrap a .main .theInfo .the-text {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .kvWrap a .main .theInfo .the-text {
    max-height: 45px;
  }
}

@media (min-width: 1025px) {
  .kvWrap a .main .theInfo .the-text {
    max-height: 66px;
  }
}

.kvWrap a .main .theInfo .btn-more {
  display: inline-block;
  color: #408ab4;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  background: white;
  float: right;
  border: none;
  cursor: pointer;
  margin-top: 15px;
  transition: background 0.5s ease-in-out;
}

@media (max-width: 767px) {
  .kvWrap a .main .theInfo .btn-more {
    padding: 8px;
  }
}

@media (min-width: 768px) {
  .kvWrap a .main .theInfo .btn-more {
    padding: 8px 12px;
  }
}

.kvWrap a .main .theInfo .btn-more:hover {
  color: #00aeef;
  background: white;
}

@media (max-width: 767px) {
  .swiper-slide-active .kvWrap a .main .theInfo {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: .8s ease .5s;
  }
}

@media (min-width: 768px) {
  .swiper-slide-active .kvWrap a .main .theInfo {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: .4s ease 1s;
  }
}

@media (max-width: 767px) {
  .swiper-slide-active .kvWrap a .main .the-title {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: .8s ease .7s;
  }
}

@media (min-width: 768px) {
  .swiper-slide-active .kvWrap a .main .the-title {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: .8s ease 1.2s;
  }
}

.swiper-slide-active .kvWrap a .main .the-text {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: .8s ease 1.5s;
}

/* Solutions -------------------------------- */
@media (max-width: 767px) {
  .Solutions {
    height: 150px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Solutions {
    height: 286px;
  }
}

@media (min-width: 1025px) {
  .Solutions {
    height: 255px;
    padding-bottom: 35px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Solutions.swiper-container .swiper-pagination {
    display: block;
    bottom: 4px;
  }
}

@media (min-width: 1025px) {
  .Solutions.swiper-container .swiper-pagination {
    display: block;
    bottom: 4px;
  }
}

/* SolutionsWrap */
.SolutionsWrap {
  position: relative;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 767px) {
  .SolutionsWrap {
    height: 150px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .SolutionsWrap {
    height: 286px;
  }
}

@media (min-width: 1025px) {
  .SolutionsWrap {
    height: 255px;
  }
}

.SolutionsWrap a {
  display: block;
  overflow: hidden;
}

.SolutionsWrap a .gradient {
  max-width: 100%;
  margin: auto;
}

.SolutionsWrap a .gradient img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}

.SolutionsWrap a .gradient:after {
  display: block;
  position: relative;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 62%, #000 96%);
  background-image: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0) 62%, #000 96%);
  background-image: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0) 62%, #000 96%);
  background-image: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0) 62%, #000 96%);
  margin-top: -150px;
  height: 150px;
  width: 100%;
  content: '';
  position: absolute;
  bottom: 0px;
}

.SolutionsWrap a .theInfo {
  position: absolute;
}

@media (max-width: 767px) {
  .SolutionsWrap a .theInfo {
    width: calc( 100% - 24px);
    padding: 12px;
    bottom: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .SolutionsWrap a .theInfo {
    width: calc( 100% - 24px);
    padding: 12px;
    bottom: 17px;
  }
}

@media (min-width: 1025px) {
  .SolutionsWrap a .theInfo {
    width: calc( 100% - 70px);
    padding: 35px;
    height: calc( 100% - 70px);
    bottom: -160px;
    background-color: rgba(0, 160, 233, 0);
    transition: all 0.5s ease-in-out;
  }
}

.SolutionsWrap a .theInfo .the-title {
  color: #ffffff;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.85) 0px 1em 5em;
  margin-bottom: 12px;
  height: 48px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .SolutionsWrap a .theInfo .the-title {
    font-size: 15px;
    line-height: 20px;
    height: 40px;
    margin-bottom: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .SolutionsWrap a .theInfo .the-title {
    margin-bottom: 0px;
    height: 25px;
  }
}

.SolutionsWrap a .theInfo .the-text {
  color: #ffffff;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  margin-top: 12px;
  border-top: 1px solid #ffffff;
  padding: 12px 12px 0px;
}

@media (max-width: 1024px) {
  .SolutionsWrap a .theInfo .the-text {
    display: none;
  }
}

@media (min-width: 1025px) {
  .SolutionsWrap a:hover .theInfo {
    bottom: 0px;
    background-color: rgba(0, 160, 233, 0.85);
  }
}

/* Products -------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) {
  .Products {
    height: 270px;
  }
}

@media (min-width: 1025px) {
  .Products {
    height: 255px;
    padding-bottom: 35px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Products.swiper-container .swiper-pagination {
    display: block;
    bottom: 4px;
  }
}

@media (min-width: 1025px) {
  .Products.swiper-container .swiper-pagination {
    display: block;
    bottom: 4px;
  }
}

/* ProductsWrap */
.ProductsWrap {
  width: 100%;
  overflow: hidden;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ProductsWrap {
    height: 270px;
  }
}

@media (min-width: 1025px) {
  .ProductsWrap {
    height: 255px;
  }
}

.ProductsWrap a {
  display: block;
  overflow: hidden;
  text-decoration: none;
}

.ProductsWrap a .itemImg {
  position: relative;
  max-width: 100%;
  margin: auto;
}

@media (max-width: 767px) {
  .ProductsWrap a .itemImg {
    height: 150px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ProductsWrap a .itemImg {
    height: 206px;
  }
}

@media (min-width: 1025px) {
  .ProductsWrap a .itemImg {
    height: 180px;
    overflow: hidden;
  }
}

.ProductsWrap a .itemImg img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}

.ProductsWrap a .theInfo {
  overflow: hidden;
}

@media (max-width: 767px) {
  .ProductsWrap a .theInfo {
    width: calc( 100% - 20px);
    margin: 10px;
    height: 56px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ProductsWrap a .theInfo {
    width: calc( 100% - 30px);
    margin: 15px;
    height: 35px;
  }
}

@media (min-width: 1025px) {
  .ProductsWrap a .theInfo {
    width: calc(100% - 40px);
    margin: 20px;
    height: 40px;
  }
}

@media (max-width: 1024px) {
  .ProductsWrap a .theInfo .the-title {
    font-size: 15px;
    line-height: 18px;
  }
}

@media (min-width: 1025px) {
  .ProductsWrap a .theInfo .the-title {
    color: #666666;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 12px;
    height: 40px;
    overflow: hidden;
  }
}

.ProductsWrap a:hover .theInfo .the-title {
  color: #00aeef;
}

/* news -------------------------------- */
@media (min-width: 1025px) {
  .news {
    padding-bottom: 35px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .news.swiper-container .swiper-pagination {
    display: block;
    bottom: 4px;
  }
}

@media (min-width: 1025px) {
  .news.swiper-container .swiper-pagination {
    display: block;
    bottom: 4px;
  }
}

/* newsWrap */
.newsWrap {
  width: calc( 100% - 2px);
  overflow: hidden;
  border: 1px solid #dddddd;
}

.newsWrap a {
  display: block;
  overflow: hidden;
  text-decoration: none;
}

.newsWrap a .itemImg {
  position: relative;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
}

@media (max-width: 767px) {
  .newsWrap a .itemImg {
    height: 250px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .newsWrap a .itemImg {
    height: 206px;
  }
}

@media (min-width: 1025px) {
  .newsWrap a .itemImg {
    height: 200px;
  }
}

.newsWrap a .itemImg img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}

.newsWrap a .theInfo {
  overflow: hidden;
  width: calc( 100% - 30px);
  margin: 15px;
}

@media (max-width: 767px) {
  .newsWrap a .theInfo {
    height: 185px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .newsWrap a .theInfo {
    height: 185px;
  }
}

@media (min-width: 1025px) {
  .newsWrap a .theInfo {
    height: 316px;
  }
}

.newsWrap a .theInfo .the-title {
  margin-bottom: 20px;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .newsWrap a .theInfo .the-title {
    max-height: 60px;
    color: #333333;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }
}

@media (min-width: 1025px) {
  .newsWrap a .theInfo .the-title {
    max-height: 80px;
    color: #333333;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }
}

.newsWrap a .theInfo .the-text {
  overflow: hidden;
  margin-bottom: 8px;
  color: #6d6e71;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: left;
}

@media (max-width: 1024px) {
  .newsWrap a .theInfo .the-text {
    max-height: 80px;
  }
}

@media (min-width: 1025px) {
  .newsWrap a .theInfo .the-text {
    max-height: 180px;
  }
}

.newsWrap a .theInfo .date {
  margin-bottom: 8px;
  color: #00aeef;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

@media (min-width: 1025px) {
  .newsWrap a:hover .theInfo .the-title {
    color: #00aeef;
  }
}

/* explore -------------------------------- */
@media (min-width: 1025px) {
  .explore {
    padding-bottom: 35px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .explore.swiper-container .swiper-pagination {
    display: block;
    bottom: 4px;
  }
}

@media (min-width: 1025px) {
  .explore.swiper-container .swiper-pagination {
    display: block;
    bottom: 4px;
  }
}

.explore .swiper-slide {
  background: none;
}

@media (min-width: 1025px) {
  .explore .swiper-slide:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    /* box-shadow: x y blur spread color inset */
  }
}

/* exploreWrap */
.exploreWrap {
  overflow: hidden;
}

.exploreWrap a {
  display: block;
  overflow: hidden;
  text-decoration: none;
}

.exploreWrap a .itemImg {
  height: 0;
  padding-top: 30px;
  overflow: hidden;
  position: relative;
}

@media (max-width: 767px) {
  .exploreWrap a .itemImg {
    width: 100%;
    padding-bottom: 53.25%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .exploreWrap a .itemImg {
    width: 100%;
    padding-bottom: 53.25%;
  }
}

@media (min-width: 1025px) {
  .exploreWrap a .itemImg {
    width: calc( 100% - 410px);
    padding-bottom: 29.25%;
  }
}

.exploreWrap a .itemImg iframe, .exploreWrap a .itemImg object, .exploreWrap a .itemImg embed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.exploreWrap a .theInfo {
  overflow: hidden;
}

@media (max-width: 767px) {
  .exploreWrap a .theInfo {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .exploreWrap a .theInfo {
    width: 100%;
  }
}

@media (min-width: 1025px) {
  .exploreWrap a .theInfo {
    width: 380px;
    float: left;
    margin-right: 30px;
  }
}

.exploreWrap a .theInfo .the-title {
  margin-bottom: 20px;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .exploreWrap a .theInfo .the-title {
    max-height: 60px;
    color: #333333;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }
}

@media (min-width: 1025px) {
  .exploreWrap a .theInfo .the-title {
    max-height: 80px;
    color: #333333;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
  }
}

.exploreWrap a .theInfo .the-text {
  overflow: hidden;
  margin-bottom: 20px;
  color: #6d6e71;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: left;
}

@media (max-width: 1024px) {
  .exploreWrap a .theInfo .the-text {
    max-height: 80px;
  }
}

@media (min-width: 1025px) {
  .exploreWrap a .theInfo .the-text {
    max-height: 180px;
  }
}

/* Social -------------------------------- */
@media (max-width: 767px) {
  .space {
    margin-bottom: 25px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .space {
    margin-bottom: 40px;
  }
}

@media (min-width: 1025px) {
  .space {
    margin-bottom: 50px;
  }
}

.Social {
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

@media (min-width: 1025px) {
  .Social {
    padding: 0px 10px;
  }
}

.Social > div {
  float: left;
}

@media (max-width: 767px) {
  .Social:nth-child(even) > div:nth-child(1) {
    order: 2;
  }
}

@media (min-width: 768px) {
  .Social:nth-child(even) > div:nth-child(1) {
    order: 2;
  }
}

@media (min-width: 768px) {
  .Social:nth-child(even) > div:nth-child(2) {
    order: 1;
  }
}

@media (max-width: 767px) {
  .Social:nth-child(odd) > div:nth-child(1) {
    order: 2;
  }
}

.Social .theInfo {
  overflow: hidden;
}

@media (max-width: 767px) {
  .Social .theInfo {
    width: 100%;
    margin-top: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Social .theInfo {
    width: calc( 100% - 430px);
  }
}

@media (min-width: 1025px) {
  .Social .theInfo {
    width: calc( 100% - 430px);
  }
}

.Social .theInfo .the-title {
  margin-bottom: 20px;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .Social .theInfo .the-title {
    color: #333333;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }
}

@media (min-width: 1025px) {
  .Social .theInfo .the-title {
    color: #333333;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
  }
}

.Social .theInfo .the-text {
  overflow: hidden;
  margin-bottom: 20px;
  color: #6d6e71;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: left;
}

.Social .theInfo .btn-more {
  display: inline-block;
  color: #ffffff;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  background-color: rgba(0, 160, 233, 0.75);
  transition: background-color 0.5s ease-in-out;
  float: right;
  text-decoration: none;
}

@media (max-width: 767px) {
  .Social .theInfo .btn-more {
    padding: 8px;
  }
}

@media (min-width: 768px) {
  .Social .theInfo .btn-more {
    padding: 12px;
  }
}

.Social .theInfo .btn-more:hover {
  background-color: #00a0e9;
}

.Social .itemImg {
  position: relative;
  overflow: hidden;
}

@media (max-width: 767px) {
  .Social .itemImg {
    width: 100%;
    height: 240px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Social .itemImg {
    width: 400px;
    height: 266px;
  }
}

@media (min-width: 1025px) {
  .Social .itemImg {
    width: 400px;
    height: 266px;
  }
}

.Social .itemImg img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}
