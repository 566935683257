@mixin box-shadow($box-shadow: 7px 7px 10px 3px rgba(0%,0%,0%,0.5)) {
  box-shadow: $box-shadow;
  /* box-shadow: x y blur spread color inset */ }

@mixin fontstyle($color: $gray-800, $font-weight: normal, $font-size: $text18, $line-height: 16px, $text-align: left) {
  color: $color;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  text-align: $text-align; }

@mixin button($display: inline-block, $border: 1px solid $gray-600, $color: $gray-600, $padding: 8px,$font-size: 16px, $text-decoration: none) {
  display: $display;
  border: $border;
  color: $color;
  padding: $padding;
  font-size: $font-size;
  text-decoration: $text-decoration; }

$efont-family: 'verdana';
$gradientBG: linear-gradient(to bottom,  #53c6f1 0%,#53c6f1 85%,#40b8f1 100%);
$gradientBG-gray: linear-gradient(to bottom,  #ebebeb 8%,#ebebeb 10%,#fff 50%);
$gray-line-border: 1px solid $main-gray-line;

@mixin hoverEffect($transition: all 0.5s ease-in-out) {
  transition: $transition; }

@mixin flexbox($display: flex, $flex-flow: row wrap, $justify-content: flex-start) {
  display: $display;
  flex-flow: $flex-flow;
  justify-content: $justify-content; }

@mixin aLink($margin: 10px 0px 10px, $transition: all 0.5s ease-in-out) {
  transition: $transition;
  margin: $margin; }

@mixin aHoverMove($margin: 0px 0px 20px) {
  margin: $margin; }

@mixin triangle($width: 0px, $height: 0px, $border-style: solid, $border-width: 17px 0 17px 20px, $border-color: transparent transparent transparent #46b3e6) {
  width: $width;
  height: $height;
  border-style: $border-style;
  border-width: $border-width;
  border-color: $border-color; }
