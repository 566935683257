@import '../components/settings';
@import '../components/uiColorPalette';
@import '../components/mixin';
@import '../components/mediaQuery';
// *************************************
//
// Pages
// -> index
//
// *************************************
@import '../components/swiper-public';
/* kv -------------------------------- */
.kv {
  @include mobile {
    height: auto;
    padding-bottom: 0px; }
  @include tablet {
    height: 475px;
    padding-bottom: 0px; }
  @include desktop {
    height: 475px;
    padding: 0px; }
  .swiper-slide {
    @include mobile {
      height: auto;
      //height: 440px
      a {
        display: flex; } } }
  .noInfo {
    @include mobile {
      height: 250px;
      a {
        display: block; } } }
  .swiper-button-next, .swiper-button-prev {
    width: 27px;
    height: 44px;
    @include belowTablet {
      display: none; }
    @include desktop {
      display: block; } }
  .swiper-pagination {
    margin-bottom: 15px;
    @include mobile {
      top: 220px;
      bottom: unset; }
    @include belowTablet {
      display: block;
      margin-bottom: 5px; }
    @include desktop {
      display: block; } }

  .swiper-pagination-bullet {
    background: #e5e5e5;
    opacity: 1; }
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #00a0e9; }
  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    background-image: url(../../images/icon/arrow-next-w.svg); }
  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    background-image: url(../../images/icon/arrow-back-w.svg); } }

/* kvWrap */
.kvWrap {
  width: 100%;
  overflow: hidden;
  @include mobile {
 }    //height: 250px
  @include tablet {
    height: 475px; }
  @include desktop {
    height: 475px; }
  a {
    overflow: hidden;
    @include mobile {
      display: flex;
      flex-flow: row wrap; }
    @include tablet {
      display: block; }
    @include desktop {
      display: block; }
    .itemImg {
      @include mobile {
        width: 100%;
        overflow: hidden;
        height: 250px;
        order: 1; }
      @include tablet {
        position: relative;
        max-width: 100%;
        margin: auto;
        height: 475px; }
      @include desktop {
        position: relative;
        max-width: 100%;
        margin: auto;
        height: 475px;
        overflow: hidden; }
      img {
        @include aboveTablet {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          min-height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center center;
          font-family: 'object-fit: cover; object-position: center center;'; }
        &.pic-mobile {
          @include mobile {
            display: block; }
          @include aboveTablet {
            display: none; } }
        &.pic-desktop {
          @include mobile {
            display: none; }
          @include aboveTablet {
            display: block; } } } }
    .main {
      @include mobile {
        order: 2;
        padding: 0px;
        width: 100%; }
      //  bottom : 25px
      @include tablet {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        height: 425px;
        bottom : 25px; }
      @include desktop {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        height: 475px;
        bottom : 0px; }
      .theInfo {
        //position: absolute
        //bottom: 20px
        display: block;
        background: linear-gradient(to bottom,rgba(25,92,196,0.9) 0,rgba(13,121,212,0.9) 50%,rgba(1,159,232,0.5) 100%);
        background: -moz-linear-gradient(to bottom,rgba(25,92,196,0.9) 0,rgba(13,121,212,0.9) 50%,rgba(1,159,232,0.5) 100%);
        background: -webkit-linear-gradient(to bottom,rgba(25,92,196,0.9) 0,rgba(13,121,212,0.9) 50%,rgba(1,159,232,0.5) 100%);
        background: -o-linear-gradient(to bottom,rgba(25,92,196,0.9) 0,rgba(13,121,212,0.9) 50%,rgba(1,159,232,0.5) 100%);
        //animation-name: bgFunction
        //animation-duration: 2s
        //transform: translate3d(20px, 0, 0)

        @include mobile {
          height: auto;
          width: calc( 100% - 30px);
          padding: 15px; }
        //  margin: 0 auto
        //opacity: 0
        //transform: translate3d(0, 0, 0)
        //transition: .8s ease .6s
        @include tablet {
          position: absolute;
          bottom: 20px;
          width: calc( 100% - 86px);
          padding: 25px;
          opacity: 0;
          transform: translate3d(0, 30px, 0);
          transition: .8s ease .6s; }
        @include desktop {
          position: absolute;
          bottom: 20px;
          width: 620px;
          padding: 18px;
          left: 10px;
          opacity: 0;
          transform: translate3d(0, 30px, 0);
          transition: .8s ease .6s; }

        .the-title {
          overflow: hidden;
          opacity: 0;
          transform: translate3d(0, 30px, 0);
          transition: .8s ease .6s;
          @include belowTablet {
            @include fontstyle($color: $white, $font-weight: normal, $font-size: 24px, $line-height: 32px, $text-align: left);
            max-height: 160px; }
          @include desktop {
            @include fontstyle($color: $white, $font-weight: normal, $font-size: 26px, $line-height: 34px, $text-align: left);
            max-height: 68px; } }
        .the-text {
          @include fontstyle($color: $white, $font-weight: normal, $font-size: 16px, $line-height: 22px, $text-align: left);
          overflow: hidden;
          margin-top: 5px;
          opacity: 0;
          transform: translate3d(0, 30px, 0);
          transition: .8s ease .6s;
          @include mobile {
            //height: auto
            display: none; }
          @include tablet {
            max-height: 45px; }
          @include desktop {
            max-height: 66px; } }
        .btn-more {
          display: inline-block;
          @include fontstyle($color: $main-Blue-4, $font-weight: normal, $font-size: 14px, $line-height: 14px, $text-align: center);
          background: rgba($white, 1);
          float: right;
          border: none;
          cursor: pointer;
          margin-top: 15px;
          @include mobile {
            padding: 8px; }
          @include aboveTablet {
            padding: 8px 12px; }
          @include hoverEffect($transition: background 0.5s ease-in-out);
          &:hover {
            color: $main-Blue-2;
            background: rgba($white, 1); } } } } } }
.swiper-slide-active .kvWrap a .main {
  .theInfo {
    @include mobile {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: .8s ease .5s; }
    @include aboveTablet {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: .4s ease 1s; } }

  .the-title {
    @include mobile {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: .8s ease .7s; }
    @include aboveTablet {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: .8s ease 1.2s; } }
  .the-text {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: .8s ease 1.5s; } }
//@keyframes bgFunction
//  from
//    opacity: 0
//  to
//    opacity: 1

/* Solutions -------------------------------- */
.Solutions {
  @include mobile {
    height: 150px; }
  @include tablet {
    height: 286px; }
  @include desktop {
    height: 255px;
    padding-bottom: 35px; }
  &.swiper-container .swiper-pagination {
    @include tablet {
      display: block;
      bottom: 4px; }
    @include desktop {
      display: block;
      bottom: 4px; } } }

/* SolutionsWrap */
.SolutionsWrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  @include mobile {
    height: 150px; }
  @include tablet {
    height: 286px; }
  @include desktop {
    height: 255px; }
  a {
    display: block;
    overflow: hidden;
    .gradient {
      max-width: 100%;
      margin: auto;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        min-height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center center; }
      &:after {
        display: block;
        position: relative;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 62%, #000 96%);
        background-image: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0) 62%, #000 96%);
        background-image: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0) 62%, #000 96%);
        background-image: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0) 62%, #000 96%);
        margin-top: -150px;
        height: 150px;
        width: 100%;
        content: '';
        position: absolute;
        bottom: 0px; } }
    .theInfo {
      position: absolute;
      @include mobile {
        width: calc( 100% - 24px );
        padding: 12px;
        bottom: 0px; }
      @include tablet {
        width: calc( 100% - 24px );
        padding: 12px;
        bottom: 17px; }
      @include desktop {
        width: calc( 100% - 70px );
        padding: 35px;
        height: calc( 100% - 70px );
        bottom: -160px;
        background-color: rgba(#00a0e9, 0);
        @include hoverEffect($transition: all 0.5s ease-in-out); }
      .the-title {
        @include fontstyle($color: $white, $font-weight: normal, $font-size: 18px, $line-height: 24px, $text-align: center);
        text-shadow: rgba( #000, 0.85 ) 0px 1em 5em;
        margin-bottom: 12px;
        height: 48px;
        overflow: hidden;
        @include mobile {
          font-size: 15px;
          line-height: 20px;
          height: 40px;
          margin-bottom: 0px; }
        @include tablet {
          margin-bottom: 0px;
          height: 25px; } }
      .the-text {
        @include fontstyle($color: $white, $font-weight: normal, $font-size: 15px, $line-height: 22px, $text-align: center);
        margin-top: 12px;
        border-top: 1px solid  $white;
        padding: 12px 12px 0px;
        @include belowTablet {
          display: none; } } }
    &:hover {
      .theInfo {
        @include desktop {
          bottom: 0px;
          background-color: rgba(#00a0e9, 0.85); } } } } }

/* Products -------------------------------- */
.Products {
  @include mobile;
  @include tablet {
    height: 270px; }
  @include desktop {
    height: 255px;
    padding-bottom: 35px; }
  &.swiper-container .swiper-pagination {
    @include tablet {
      display: block;
      bottom: 4px; }
    @include desktop {
      display: block;
      bottom: 4px; } } }

/* ProductsWrap */
.ProductsWrap {
  width: 100%;
  overflow: hidden;
  @include mobile;
  @include tablet {
    height: 270px; }
  @include desktop {
    height: 255px; }
  a {
    display: block;
    overflow: hidden;
    text-decoration: none;
    .itemImg {
      position: relative;
      max-width: 100%;
      margin: auto;
      @include mobile {
        height: 150px; }
      @include tablet {
        height: 206px; }
      @include desktop {
        height: 180px;
        overflow: hidden; }
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        min-height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center center; } }

    .theInfo {
      overflow: hidden;
      @include mobile {
        width: calc( 100% - 20px );
        margin: 10px;
        height: 56px; }
      @include tablet {
        width: calc( 100% - 30px );
        margin: 15px;
        height: 35px; }
      @include desktop {
        width: calc(100% - 40px);
        margin: 20px;
        height: 40px; }
      .the-title {
        @include belowTablet {
          font-size: 15px;
          line-height: 18px; }
        @include desktop {
          @include fontstyle($color: $main-gray-6, $font-weight: normal, $font-size: 16px, $line-height: 20px, $text-align: center);
          margin-bottom: 12px;
          height: 40px;
          overflow: hidden; } } }
    &:hover {
      .theInfo .the-title {
        color: $main-Blue-2; } } } }

/* news -------------------------------- */
.news {
  @include desktop {
    padding-bottom: 35px; }
  &.swiper-container .swiper-pagination {
    @include tablet {
      display: block;
      bottom: 4px; }
    @include desktop {
      display: block;
      bottom: 4px; } } }
/* newsWrap */
.newsWrap {
  width: calc( 100% - 2px );
  overflow: hidden;
  border: 1px solid $main-gray-line;
  a {
    display: block;
    overflow: hidden;
    text-decoration: none;
    .itemImg {
      position: relative;
      max-width: 100%;
      margin: auto;
      overflow: hidden;
      @include mobile {
        height: 250px; }
      @include tablet {
        height: 206px; }
      @include desktop {
        height: 200px; }
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        min-height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center center; } }

    .theInfo {
      overflow: hidden;
      width: calc( 100% - 30px );
      margin: 15px;
      @include mobile {
        height: 185px; }
      @include tablet {
        height: 185px; }
      @include desktop {
        height: 316px; }
      .the-title {
        margin-bottom: 20px;
        overflow: hidden;
        @include belowTablet {
          max-height: 60px;
          @include fontstyle($color: $main-gray-7, $font-weight: bold, $font-size: 16px, $line-height: 20px, $text-align: left); }
        @include desktop {
          max-height: 80px;
          @include fontstyle($color: $main-gray-7, $font-weight: bold, $font-size: 16px, $line-height: 20px, $text-align: left); } }
      .the-text {
        overflow: hidden;
        margin-bottom: 8px;
        @include fontstyle($color: #6d6e71, $font-weight: normal, $font-size: 15px, $line-height: 20px, $text-align: left);
        @include belowTablet {
          max-height: 80px; }
        @include desktop {
          max-height: 180px; } }
      .date {
        margin-bottom: 8px;
        @include fontstyle($color: $main-Blue-2, $font-weight: normal, $font-size: 14px, $line-height: 20px, $text-align: left); } }
    &:hover .theInfo .the-title {
      @include desktop {
        color: $main-Blue-2; } } } }

/* explore -------------------------------- */
.explore {
  @include desktop {
    padding-bottom: 35px; }
  &.swiper-container .swiper-pagination {
    @include tablet {
      display: block;
      bottom: 4px; }
    @include desktop {
      display: block;
      bottom: 4px; } }
  .swiper-slide {
    background: none;
    @include desktop {
      &:hover {
        @include box-shadow($box-shadow: 0px 0px 0px 0px rgba(0%,0%,0%,0.0)); } } } }
/* exploreWrap */
.exploreWrap {
  overflow: hidden;
  a {
    display: block;
    overflow: hidden;
    text-decoration: none;
    .itemImg {
      height: 0;
      padding-top: 30px;
      overflow: hidden;
      position: relative;
      @include mobile {
        width: 100%;
        padding-bottom: 53.25%; }
      @include tablet {
        width: 100%;
        padding-bottom: 53.25%; }
      @include desktop {
        width: calc( 100% - 410px );
        padding-bottom: 29.25%; }
      iframe, object, embed {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; } }

    .theInfo {
      overflow: hidden;
      @include mobile {
        width: 100%; }
      @include tablet {
        width: 100%; }
      @include desktop {
        width: 380px;
        float: left;
        margin-right: 30px; }
      .the-title {
        margin-bottom: 20px;
        overflow: hidden;
        @include belowTablet {
          max-height: 60px;
          @include fontstyle($color: $main-gray-7, $font-weight: bold, $font-size: 16px, $line-height: 20px, $text-align: left); }
        @include desktop {
          max-height: 80px;
          @include fontstyle($color: $main-gray-7, $font-weight: normal, $font-size: 24px, $line-height: 30px, $text-align: left); } }
      .the-text {
        overflow: hidden;
        margin-bottom: 20px;
        @include fontstyle($color: #6d6e71, $font-weight: normal, $font-size: 15px, $line-height: 20px, $text-align: left);
        @include belowTablet {
          max-height: 80px; }
        @include desktop {
          max-height: 180px; } } } } }
/* Social -------------------------------- */
.space {
  @include mobile {
    margin-bottom: 25px; }
  @include tablet {
    margin-bottom: 40px; }
  @include desktop {
    margin-bottom: 50px; } }
.Social {
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  @include desktop {
    padding: 0px 10px; }
  &> div {
    float: left; }
  &:nth-child(even) > div:nth-child(1) {
    @include mobile {
      order: 2; }
    @include aboveTablet {
      order: 2; } }
  &:nth-child(even) > div:nth-child(2) {
    @include aboveTablet {
      order: 1; } }
  &:nth-child(odd) > div:nth-child(1) {
    @include mobile {
      order: 2; } }
  .theInfo {
    overflow: hidden;
    @include mobile {
      width: 100%;
      margin-top: 15px; }
    @include tablet {
      width: calc( 100% - 430px ); }
    @include desktop {
      width: calc( 100% - 430px ); }
    .the-title {
      margin-bottom: 20px;
      overflow: hidden;
      @include belowTablet {
        @include fontstyle($color: $main-gray-7, $font-weight: bold, $font-size: 16px, $line-height: 20px, $text-align: left); }
      @include desktop {
        @include fontstyle($color: $main-gray-7, $font-weight: normal, $font-size: 24px, $line-height: 30px, $text-align: left); } }
    .the-text {
      overflow: hidden;
      margin-bottom: 20px;
      @include fontstyle($color: #6d6e71, $font-weight: normal, $font-size: 15px, $line-height: 20px, $text-align: left);
      // +belowTablet
      //   max-height: 80px
      // +desktop
 }      //   max-height: 103px
    .btn-more {
      display: inline-block;
      @include fontstyle($color: $white, $font-weight: normal, $font-size: 14px, $line-height: 14px, $text-align: center);
      background-color: rgba(#00a0e9, 0.75 );
      @include hoverEffect($transition: background-color 0.5s ease-in-out);
      float: right;
      text-decoration: none;
      @include mobile {
        padding: 8px; }
      @include aboveTablet {
        padding: 12px; }
      &:hover {
        background-color: rgba(#00a0e9, 1 ); } } }
  .itemImg {
    position: relative;
    overflow: hidden;
    @include mobile {
      width: 100%;
      height: 240px; }
    @include tablet {
      width: 400px;
      height: 266px; }
    @include desktop {
      width: 400px;
      height: 266px; }
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      min-height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center center; } } }

