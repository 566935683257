@import "../../../node_modules/susy/sass/_susy.scss";
@import '../../../node_modules/susy/sass/plugins/svg-grid';
@mixin susy-use(
  $config
) {
  //  parse and normalize any shorthand arguments
  $config: susy-compile($config);

  // record the global settings -
  // and update the global variable with our new settings
  $global: $susy;
  $susy: map-merge($susy, $config) !global;

  // any content inside this mixin
  // will use the local settings
  @content;

  // return the global variable to its initial value
  $susy: $global !global;
};

@mixin susy-at(
  $config
) {
  //  parse and normalize any shorthand arguments
  $config: susy-compile($config);

  // build min-and-max queries
  $min: map-get($config, 'min-width');
  $min: if($min, '(min-width: #{$min})', null);
  $max: map-get($config, 'max-width');
  $max: if($max, '(max-width: #{$max})', null);

  // combine them if we need both
  $and: if($min and $max, '#{$min} and #{$max}', null);
  // or fall back to the value we need…
  $query: $and or $min or $max;

  // apply the results…
  @media #{$query} {
    @include susy-use($config) {
      @content;
    }
  }
}

$mobile: (
  'media': 'only screen',
  'max-width': '767px',
  'columns': susy-repeat(4),
  'gutters': 0.11,
  'spread': 'narrow',
  'container-spread': 'narrow'
);
$tablet: (
  'media': 'only screen',
  'min-width': '768px',
  'max-width': '1024px',
  'columns': susy-repeat(8),
  'gutters': 0.16,
  'spread': 'narrow',
  'container-spread': 'narrow'
);
$desktop: (
  'media': 'only screen',
  'min-width': '1025px',
  'columns': susy-repeat(12),
  'gutters': 0.3,
  'spread': 'narrow',
  'container-spread': 'narrow'
);
$belowTablet: (
  'media': 'only screen',
  'max-width': '1024px',
  'spread': 'narrow',
  'container-spread': 'narrow'
);
$aboveTablet: (
  'media': 'only screen',
  'min-width': '768px',
  'spread': 'narrow',
  'container-spread': 'narrow'
);